import { createSlice } from "@reduxjs/toolkit"

interface ThemeState {
  value: boolean
}

const initialState = { value: true } as ThemeState

const themeSlice = createSlice({
  name: "Theme",
  initialState,
  reducers: {
    setTheme: state => {
      state.value ? (state.value = false) : (state.value = true)
    },
  },
})

export const { setTheme } = themeSlice.actions
export default themeSlice.reducer
