import React from "react"
import { Provider } from "react-redux"
import store from "./src/store"
import { Theme } from "./src/styles/theme"

import { DEFAULT_OPTIONS, SCRIPT_ELEMENT_ID } from "./src/store/constants"

import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist"

const persistor = persistStore(store)

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Theme>{element}</Theme>
      </PersistGate>
    </Provider>
  )
}

export const onInitialClientRender = (_, pluginOptions = {}) => {
  const shouldCleanup = Boolean(
    pluginOptions.cleanupOnClient ?? DEFAULT_OPTIONS.cleanupOnClient
  )
  if (shouldCleanup) {
    const windowKey = pluginOptions.windowKey ?? DEFAULT_OPTIONS.windowKey
    delete window[windowKey]

    const preloadedStateEl = document.getElementById(SCRIPT_ELEMENT_ID)
    if (preloadedStateEl) {
      preloadedStateEl.parentNode.removeChild(preloadedStateEl)
    }
  }
}
