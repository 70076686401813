import { configureStore } from "@reduxjs/toolkit"
import {
  createMigrate,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import storage from "redux-persist/lib/storage"
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2"
import logger from "redux-logger"
import rootReducer from "./reducers/rootReducer"
import { ThemeState } from "./types"

// Reducers
export interface StoreState {
  theme: ThemeState
}

// Migrations
const migrations = {
  1: state => {
    return {
      ...state,
    }
  },
}

const persistConfig = {
  key: "___gatsby",
  storage,
  migrate: createMigrate(migrations, { debug: true }),
  stateReconciler: autoMergeLevel2,
  version: 1,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: {
    persistedReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(logger),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
