import * as React from "react"
import { useSelector } from "react-redux"

import {
  ThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"

import { RootState } from "../store"

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    base: Palette["primary"]
    opaque: Palette["background"]
  }
  interface PaletteOptions {
    base: PaletteOptions["primary"]
    opaque: Palette["background"]
  }
}

import "@fontsource/pacifico/400.css"
import "@fontsource/roboto-slab/300.css"
import "@fontsource/roboto-slab/400.css"
import "@fontsource/roboto-slab/500.css"
import "@fontsource/roboto-slab/700.css"

export const muiTheme = (isLit: boolean) => {
  return responsiveFontSizes(
    createMuiTheme({
      overrides: {
        MuiCssBaseline: {
          "@global": {
            html: {
              WebkitFontSmoothing: "auto",
            },
            body: {
              backgroundColor: isLit ? "#fbfbfe" : "#222222",
            },
          },
        },
        MuiDrawer: {
          paper: {
            backgroundColor: isLit ? "#fbfbfe" : "#222222",
          },
        },
        MuiOutlinedInput: {
          root: {
            borderColor: isLit ? "#222222" : "#fefefe",
            backgroundColor: isLit ? "#fbfbfe" : "#000000",
          },
        },
      },
      typography: {
        fontFamily: ["Roboto Slab", "serif"].join(","),
      },
      palette: {
        type: isLit ? "light" : "dark",
        background: {
          default: isLit ? "#fefefe" : "#222222",
          paper: isLit ? "#ffffff" : "#000000",
        },
        opaque: {
          default: isLit ? "#b4cacaca" : "#111111ca",
          paper: isLit ? "#ffffffca" : "#111111ca",
        },
        base: {
          main: "#016b67",
        },
        primary: {
          main: isLit ? "#016b67" : "#b4caca",
          dark: isLit ? "#b4caca" : "#7aa2a1",
          light: isLit ? "#7aa2a1" : "#016b67",
        },
        secondary: {
          main: isLit ? "#F50057" : "#F50057",
          dark: isLit ? "#AB003C" : "#AB003C",
          light: isLit ? "#F73378" : "#F73378",
          contrastText: "#000",
        },
        text: {
          primary: isLit ? "#111111" : "#fefefe",
          secondary: isLit ? "#111111" : "#fefefe",
        },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
    })
  )
}
interface ThemeProps {
  children: React.ReactNode
}

export const Theme = ({ children }: ThemeProps) => {
  const isLit = useSelector(
    (state: RootState) => state.persistedReducer.theme.value
  )

  const theme = muiTheme(isLit)
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>{children}</CssBaseline>
    </ThemeProvider>
  )
}
